import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const createNegotiationRequestSchema = z.union([
  z.object({
    shiftId: z.string(),
    offerId: z.string(),
    payRate: z.number(),
  }),
  z.object({
    shiftId: z.string(),
    offerId: z.string(),
    start: z.string(),
    end: z.string(),
  }),
]);

const createNegotiationResponseSchema = z.object({
  rateNegotiationId: z.string(),
});

export type CreateNegotiationRequest = z.infer<typeof createNegotiationRequestSchema>;
export type CreateNegotiationResponse = z.infer<typeof createNegotiationResponseSchema>;

export function useCreateNegotiation(
  options: UseMutationOptions<
    CreateNegotiationResponse,
    AxiosError<CreateNegotiationResponse>,
    CreateNegotiationRequest
  > = {}
) {
  return useMutation({
    mutationFn: async (data) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/negotiation`,
        data,
        requestSchema: createNegotiationRequestSchema,
        responseSchema: createNegotiationResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_NEGOTIATION_FAILURE,
    },
    ...options,
  });
}
