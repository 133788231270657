import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const negotiationShiftDetailsResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("shift"),
    attributes: z.object({
      maxPayRate: z.number(),
    }),
  }),
});
export type NegotiationShiftDetailsResponse = z.infer<typeof negotiationShiftDetailsResponseSchema>;

export interface GetNegotiationShiftDetailsParams {
  shiftId: string;
  offerId: string;
}

export function useGetNegotiationShiftDetails(
  params: GetNegotiationShiftDetailsParams,
  options: UseGetQueryOptions<NegotiationShiftDetailsResponse> = {}
): UseQueryResult<NegotiationShiftDetailsResponse> {
  const { shiftId, offerId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/shifts/${shiftId}`,
    responseSchema: negotiationShiftDetailsResponseSchema,
    queryParams: { offerId },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_NEGOTIATION_SHIFT_DETAILS_FAILURE,
      userErrorMessage: "Error while getting negotiation shift details",
    },
    ...options,
  });
}
