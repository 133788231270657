import { Capacitor, type PluginListenerHandle } from "@capacitor/core";
import { Nfc, type NfcTagScannedEvent, NfcUtils } from "@capawesome-team/capacitor-nfc";

import { NfcReaderError, NfcReaderErrorType } from "./nfcReaderError";

class NfcReader {
  public nfcUtils = new NfcUtils();
  private timeoutHandle?: NodeJS.Timeout;
  private cancelHandle?: () => void;
  private listener?: PluginListenerHandle;

  public async readOne(timeoutInMs: number): Promise<string> {
    if (!(await this.isNfcSupported())) {
      throw new NfcReaderError(NfcReaderErrorType.UNSUPPORTED);
    }

    if (!(await this.hasNfcPermissions())) {
      throw new NfcReaderError(NfcReaderErrorType.PERMISSION_DENIED);
    }

    if (!(await this.isNfcEnabled())) {
      throw new NfcReaderError(NfcReaderErrorType.DISABLED);
    }

    if (this.timeoutHandle ?? this.listener) {
      throw new NfcReaderError(NfcReaderErrorType.SCAN_ALREADY_IN_PROGRESS);
    }

    try {
      return await this.startNfcScanWithTimeout(timeoutInMs);
    } finally {
      await this.cleanup();
    }
  }

  public async cancel(): Promise<void> {
    if (this.cancelHandle) {
      this.cancelHandle();
    }
  }

  private async startNfcScanWithTimeout(timeoutInMs: number): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return await new Promise<string>(async (resolve, reject) => {
      this.cancelHandle = async () => {
        try {
          await Nfc.stopScanSession();
          reject(new NfcReaderError(NfcReaderErrorType.SCAN_CANCEL));
        } catch {
          reject(new NfcReaderError(NfcReaderErrorType.CANCEL_FAILED));
        }
      };

      this.timeoutHandle = setTimeout(async () => {
        try {
          await Nfc.stopScanSession();
          reject(new NfcReaderError(NfcReaderErrorType.SCAN_TIMEOUT));
        } catch {
          reject(new NfcReaderError(NfcReaderErrorType.CANCEL_FAILED));
        }
      }, timeoutInMs);

      try {
        this.listener = await Nfc.addListener(
          "nfcTagScanned",
          async (event: NfcTagScannedEvent) => {
            const receivedText = this.getTextFromNfcScanEvent(event);

            if (receivedText) {
              resolve(receivedText);
            } else {
              reject(new NfcReaderError(NfcReaderErrorType.SCAN_FAILED));
            }

            try {
              await Nfc.stopScanSession();
            } catch {}
          }
        );

        await Nfc.startScanSession();
      } catch {
        reject(new NfcReaderError(NfcReaderErrorType.SCAN_FAILED));
      }
    });
  }

  private getTextFromNfcScanEvent(event: NfcTagScannedEvent): string | undefined {
    const binaryPayload = event.nfcTag?.message?.records[0]?.payload;
    if (!binaryPayload) {
      return undefined;
    }

    const textMessage = this.nfcUtils.convertBytesToString({ bytes: binaryPayload })?.text;
    if (!textMessage) {
      return undefined;
    }

    return textMessage;
  }

  private async cleanup(): Promise<void> {
    if (this.listener) {
      await this.listener.remove();
      this.listener = undefined;
    }

    if (this.cancelHandle) {
      this.cancelHandle = undefined;
    }

    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }
  }

  private async isNfcSupported(): Promise<boolean> {
    if (!Nfc) {
      return false;
    }

    if (!Capacitor.isNativePlatform()) {
      return false;
    }

    const { isSupported } = await Nfc.isSupported();

    return isSupported;
  }

  private async hasNfcPermissions(): Promise<boolean> {
    const { nfc } = await Nfc.checkPermissions();

    return nfc === "granted";
  }

  private async isNfcEnabled(): Promise<boolean> {
    const { isEnabled } = await Nfc.isEnabled();

    return isEnabled;
  }
}

let nfcReader: NfcReader | undefined;

export function startNFCScan(): NfcReader {
  if (!nfcReader) {
    nfcReader = new NfcReader();
  }

  return nfcReader;
}

export type { NfcReader };

export { NfcReaderErrorType } from "./nfcReaderError";
