import { Capacitor, type PluginListenerHandle } from "@capacitor/core";
import { NFC, type NfcTag } from "@ionic-native/nfc";
// eslint-disable-next-line no-restricted-imports
import { DeviceNFCCapabilityForShift } from "@src/lib/interface/src";

import { NfcReaderError, NfcReaderErrorType } from "./nfcReaderError";

// This is thrown as text on iOS when the user cancels the scan
const NFC_ERROR_SCAN_CANCELLED_BY_USER = "Session invalidated by user";

function parseNfcTag(tag: NfcTag): string | undefined {
  if (!tag.ndefMessage?.[0]) {
    return undefined;
  }

  const parsedData = NFC.bytesToString(tag.ndefMessage[0].payload);
  if (!parsedData) {
    return undefined;
  }

  return parsedData;
}

// This is implementing the same new NFC interface as in nfcReader.ts
// but using the old NFC plugin; this is a temporary solution to allow
// the app to build while the new NFC plugin is being worked on (TX-1740).
// If TX-1740 fails, this code will take over as the main NFC implementation.
class OldNfcReader {
  private timeoutHandle?: NodeJS.Timeout;
  private cancelHandle?: () => void;
  private listener?: PluginListenerHandle;

  public async readOne(timeoutInMs: number): Promise<string> {
    let nfcStatus: DeviceNFCCapabilityForShift;
    try {
      nfcStatus = await this.getNfcStatus();
    } catch {
      throw new NfcReaderError(NfcReaderErrorType.UNSUPPORTED);
    }

    if (nfcStatus === DeviceNFCCapabilityForShift.NO_NFC) {
      throw new NfcReaderError(NfcReaderErrorType.UNSUPPORTED);
    }

    if (nfcStatus === DeviceNFCCapabilityForShift.NFC_DISABLED) {
      throw new NfcReaderError(NfcReaderErrorType.DISABLED);
    }

    if (this.timeoutHandle ?? this.listener) {
      throw new NfcReaderError(NfcReaderErrorType.SCAN_ALREADY_IN_PROGRESS);
    }

    try {
      return await this.startNfcScanWithTimeout(timeoutInMs);
    } finally {
      await this.cleanup();
    }
  }

  public async cancel(): Promise<void> {
    if (this.cancelHandle) {
      this.cancelHandle();
    }
  }

  private async startNfcScanWithTimeout(timeoutInMs: number): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return await new Promise<string>(async (resolve, reject) => {
      this.cancelHandle = async () => {
        try {
          await this.stopScanner();
          reject(new NfcReaderError(NfcReaderErrorType.SCAN_CANCEL));
        } catch {
          reject(new NfcReaderError(NfcReaderErrorType.CANCEL_FAILED));
        }
      };

      this.timeoutHandle = setTimeout(async () => {
        try {
          await this.stopScanner();
          reject(new NfcReaderError(NfcReaderErrorType.SCAN_TIMEOUT));
        } catch {
          reject(new NfcReaderError(NfcReaderErrorType.CANCEL_FAILED));
        }
      }, timeoutInMs);

      try {
        let receivedTag: NfcTag;

        try {
          receivedTag = await this.startOsDependentScanner();
        } catch (error: unknown) {
          if (typeof error === "string" && error === NFC_ERROR_SCAN_CANCELLED_BY_USER) {
            reject(new NfcReaderError(NfcReaderErrorType.SCAN_CANCEL));
            return;
          }

          reject(new NfcReaderError(NfcReaderErrorType.SCAN_FAILED));
          return;
        } finally {
          await this.stopScanner();
        }

        const receivedText = parseNfcTag(receivedTag);
        if (receivedText) {
          resolve(receivedText);
        } else {
          reject(new NfcReaderError(NfcReaderErrorType.SCAN_FAILED));
        }
      } catch {
        reject(new NfcReaderError(NfcReaderErrorType.SCAN_FAILED));
      }
    });
  }

  private async cleanup(): Promise<void> {
    if (this.listener) {
      await this.listener.remove();
      this.listener = undefined;
    }

    if (this.cancelHandle) {
      this.cancelHandle = undefined;
    }

    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }
  }

  private async getNfcStatus(): Promise<DeviceNFCCapabilityForShift> {
    if (!NFC) {
      return DeviceNFCCapabilityForShift.NO_NFC;
    }

    if (!Capacitor.isNativePlatform()) {
      return DeviceNFCCapabilityForShift.NO_NFC;
    }

    try {
      await NFC.enabled();
    } catch (error: unknown) {
      if (typeof error !== "string") {
        throw error;
      }

      if (error === "NFC_DISABLED") {
        return DeviceNFCCapabilityForShift.NFC_DISABLED;
      }

      if (error === "NO_NFC") {
        return DeviceNFCCapabilityForShift.NO_NFC;
      }

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    }

    return DeviceNFCCapabilityForShift.NFC_ENABLED;
  }

  private async startOsDependentScanner(): Promise<NfcTag> {
    if (Capacitor.getPlatform() === "ios") {
      return await NFC.scanNdef();
    }

    return await new Promise((resolve, reject) => {
      // eslint-disable-next-line no-bitwise
      NFC.readerMode(NFC.FLAG_READER_NFC_A | NFC.FLAG_READER_NFC_V).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private async stopScanner(): Promise<void> {
    try {
      await NFC.cancelScan();
    } catch {
      // Silent failure
    }
  }
}

let nfcReader: OldNfcReader | undefined;

export function startNFCScan(): OldNfcReader {
  if (!nfcReader) {
    nfcReader = new OldNfcReader();
  }

  return nfcReader;
}

export type { OldNfcReader };
