import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { claimShiftResponseSchema } from "@src/appV2/OpenShifts/ShiftAction/types";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const acceptNegotiationRequestSchema = z.object({
  negotiationId: z.string(),
});

const acceptNegotiationResponseSchema = z.object({
  bookedShift: claimShiftResponseSchema,
  negotiation: z.object({ _id: z.string() }),
});

export type AcceptNegotiationRequest = z.infer<typeof acceptNegotiationRequestSchema>;
export type AcceptNegotiationResponse = z.infer<typeof acceptNegotiationResponseSchema>;

function getAcceptNegotiationParamsUrl(negotiationId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/accept/${negotiationId}`;
}

export function useAcceptNegotiation(
  options: UseMutationOptions<AcceptNegotiationResponse, AxiosError, AcceptNegotiationRequest> = {}
): UseMutationResult<AcceptNegotiationResponse, AxiosError, AcceptNegotiationRequest> {
  return useMutation({
    mutationFn: async (params: AcceptNegotiationRequest) => {
      const { negotiationId } = params;
      const response = await post({
        url: getAcceptNegotiationParamsUrl(negotiationId),
        responseSchema: acceptNegotiationResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_ACCEPT_NEGOTIATION_FAILURE,
    },
    ...options,
  });
}
