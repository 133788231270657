import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { RateNegotiationStatus } from "@src/appV2/Shifts/Shift/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const rateNegotiationSchema = z.object({
  shiftId: z.string(),
  status: z.nativeEnum(RateNegotiationStatus),
});

const rateNegotiationResponseSchema = z.array(rateNegotiationSchema);
export type RateNegotiation = z.infer<typeof rateNegotiationSchema>;
export type RateNegotiationResponse = z.infer<typeof rateNegotiationResponseSchema>;
export interface RateNegotiationParams {
  shiftIds?: string[];
}

export function useGetRateNegotiations(
  params: RateNegotiationParams,
  options: UseGetQueryOptions<RateNegotiationResponse> = {}
): UseQueryResult<RateNegotiationResponse> {
  const { shiftIds } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/list`,
    responseSchema: rateNegotiationResponseSchema,
    queryParams: { filter: { shiftIds } },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_RATE_NEGOTIATIONS_LIST_FAILURE,
      userErrorMessage: "Error while getting rate negotiations",
    },
    ...options,
  });
}
