import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const openNegotiationsCountResponseSchema = z.object({
  totalNegotiatingShiftsCount: z.number(),
  negotiationsWaitingOnWorker: z.number(),
  recentlyEndedNegotiationsCount: z.number(),
});

export type OpenNegotiationsCountResponse = z.infer<typeof openNegotiationsCountResponseSchema>;

export function useOpenNegotiationsCount(
  options: UseGetQueryOptions<OpenNegotiationsCountResponse> = {}
): UseQueryResult<OpenNegotiationsCountResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/workerOpenNegotiationCount`,
    responseSchema: openNegotiationsCountResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_NEGOTIATIONS_COUNT_FAILURE,
      userErrorMessage: "Error while getting open negotiations",
    },
    ...options,
  });
}
